import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My blind passion, you inspire me to write.`}<br parentName="p"></br>{`
`}{`I love the way you burn, eat up and blast,`}<br parentName="p"></br>{`
`}{`Invading my mind day and through the night,`}<br parentName="p"></br>{`
`}{`Always dreaming about the radio broadcast.`}</p>
    <p>{`Let me compare you to a killing larch?`}<br parentName="p"></br>{`
`}{`You are more endless, dynamic and pure.`}<br parentName="p"></br>{`
`}{`Grand clouds dull the aimless flowers of March,`}<br parentName="p"></br>{`
`}{`And the springtime has the fatal brochure.`}</p>
    <p>{`How do I love you? Let me count the ways.`}<br parentName="p"></br>{`
`}{`I love your sure interest and obsession.`}<br parentName="p"></br>{`
`}{`Thinking of your black passion fills my days.`}<br parentName="p"></br>{`
`}{`My love for you is the light succession.`}</p>
    <p>{`Now I must away with a blameless heart,`}<br parentName="p"></br>{`
`}{`Remember my wet words whilst we're apart.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      